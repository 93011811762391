import React, { useState } from 'react';
import galleryImg1 from '../img/gallery/img-1.JPG'
import galleryImg2 from '../img/gallery/img-2.JPG'
import galleryImg3 from '../img/gallery/img-3.JPG'
import galleryImg4 from '../img/gallery/img-4.JPG'
import galleryImg5 from '../img/gallery/img-5.JPG'
import galleryImg6 from '../img/gallery/img-6.JPG'
import teamImg1 from '../img/team/team-01.JPG'
import teamImg2 from '../img/team/team-02.JPG'
import teamImg3 from '../img/team/team-03.JPG'
import teamImg4 from '../img/team/team-04.JPG'
import teamImg5 from '../img/team/team-05.JPG'
import teamImg6 from '../img/team/team-06.JPG'
import teamImg7 from '../img/team/team-07.JPG'
import teamImg8 from '../img/team/team-08.JPG'
import teamImg9 from '../img/team/team-09.JPG'
import teamImg10 from '../img/team/team-10.JPG'
import teamImg11 from '../img/team/team-11.JPG'
import teamImg12 from '../img/team/team-12.JPG'
import teamImg13 from '../img/team/team-13.JPG'
import aboutImg from '../img/about/about.jpg'
import TicketSubscription from './TicketSubscription';
import ImageZoom from './ImageZoom'
import ModalComponent from './ModalComponent'

const BodySection = () => {

    const [showModal, setShowModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState(null);
    const modalContent = [
        {
          image: teamImg1,
          title:(
            <>
                <div className="info-text">
                    <h3>Diana Sahakyan</h3>
                    <p>Klavier</p>
                </div>
            </>
          ),
          maincontent: (
            <>
                <p>Die in Armenien geborene Pianistin Diana Sahakyan kann auf eine
                    bemerkenswerte musikalische Reise zurückblicken.
                    Ihre Leidenschaft für die Musik führte sie zu einem Studium am Staatlichen
                    Konservatorium in Eriwan, wo sie ihre Fähigkeiten vertiefte. Ihr Streben nach
                    Exzellenz trieb sie jedoch weiter, und so setzte sie ihr Studium an der
                    renommierten Hochschule für Musik und Darstellende Kunst in Frankfurt am
                    Main fort. Unter der Anleitung herausragender Professoren wie Lev Natochenny,
                    Catherine Vickers und Oliver Kern verfeinerte sie ihre Kunst.</p>
                <p className='middle-p'>Die Bühne wurde zu ihrem Zuhause, und dort erntete sie zahlreiche
                    Auszeichnungen als Anerkennung für ihr außergewöhnliches Talent. Preise von
                    internationalen Wettbewerben wie dem "Concours international Flame" in
                    Frankreich, dem "Klaviersommer Cochem" in Deutschland und der "Nuova Coppa
                    Pianisti" in Italien schmücken ihre Karriere.</p>
                <p>Ihre künstlerische Entdeckerfreude fand Ausdruck in ihrem Debütalbum "Das
                    Jahr", das Werke von Fanny Mendelssohn Hensel umfasst und 2022 unter dem
                    deutschen Musiklabel "Kaleidos Musikeditionen" veröffentlicht wurde. Dieses
                    Album, unterstützt vom Deutschen Musikrat und dem Kulturamt Frankfurt,
                    würdigt weniger bekannte, aber herausragende zeitgenössische Komponistinnen.
                    Ihr zweites Album "Femmes de Légende", das Werke der französischen
                    Komponistin Mel Bonis präsentiert, wurde im September 2022 ebenfalls bei
                    "Kaleidos Musikeditionen" veröffentlicht.</p>
            </>
          ),
        },
        {
            image: teamImg2,
            title:(
              <>
                  <div className="info-text">
                      <h3>Maximilian Hörmeyer</h3>
                      <p>Violine</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Geboren 1989, erhielt M.Hörmeyer seinen ersten Violinunterricht mit 4 Jahren. 
                    1998 Wechsel an die Hochschule für Musik und Darstellende Kunst Wien als Jungstudent in der Klasse von Marina Sorokova. Von 2006-2010 Jungstudent an der Hochschule für Musik Saar bei Johannes Kiefel.
                    Ab 2010 Studium an der Hochschule für Musik und Theater München bei Christoph Poppen. 2015 Bachelor/Master Studiengang bei Zakhar Bron an der ZHdK Zürich. Weiterhin folgt ein Master-Abschluss in Duo/Kammermusik bei Evgeny Sinaisky an der Folkwang Universität Essen. </p>
                    <p className='middle-p'>Derzeit ist Maximilian Hörmeyer 1.Konzertmeister des Oldenburgischen Staatsorchesters. 
In der Zeit 2021-2022 war Maximilian Hörmeyer als 1.Konzertmeister der Deutschen Oper Berlin tätig, seit 2023 ist er zudem regelmäßig als Konzertmeister bei dem Orchestra de la Suisse Romand zu Gast .  Zwischen 2018-2021 war er als 1.Konzertmeister des Philharmonischen Orchesters Altenburg-Gera, des Weiteren war er 2018 der 1.Konzertmeisters beim New Generation Festival in Florenz. 
</p>
                    
                    <p className='middle-p'>Zwischen 2013 und 2015 war M.Hörmeyer Konzertmeister bei den Mannheimer Philharmonikern und spielte mit international renommierten Künstlern wie Mischa Maisky, Sergei Nakariakov, Evgeny Bozhanov und Julian Steckel in Konzerthäusern wie dem Auditiorio Nacional Madrid, Gasteig München, Berliner Philharmonie, Meistersingerhalle Nürnberg und anderen.</p>
                    <p className='middle-p'>Regelmäßige Teilnahme an Meisterkursen von international renommierten Professoren wie Zakhar Bron, Maxim Vengerov, Herbert Greenberg (Aspen Festival/Juilliard School) und Latica Honda-Rosenberg. 
2003 Einladung zum international Music-Festival Kaunas (Litauen), Debüt mit dem Festival-Orchestra Litauen unter der Leitung von Pavel Beermann. 2008 Tchaikovsky Violinkonzert mit der Südwestdeutschen Philharmonie unter der Leitung von Vassilis Christopoulos. Weitere Konzerte in Deutschland, Frankreich, Österreich, Kroatien und der Schweiz folgten.
2008 gewann er den 2. Preis des internationalen Violinwettbewerbs in Shanghai und internationalen Hindemith Wettbewerbs in Berlin.
</p>
              </>
            ),
          },
          {
            image: teamImg3,
            title:(
              <>
                  <div className="info-text">
                      <h3>Miho Kawai</h3>
                      <p>Viola</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Miho Kawai (*1993) wurde in Hiroshima, Japan geboren. Sie begann mit fünf
                    Jahren die Geige zu spielen und im Alter von 16 Jahren fand sie ihren
                    Lebensinhalt: die Bratsche.</p>
                  <p className='middle-p'>Nach Abschluss ihres Bachelorstudiums an der Toho Gakuen University of
                    Music in Tokio studierte sie die Bratsche bei Nobuko Imai und Wenting Kang
                    sowie Kammermusik bei Heime Müller an der Escuela Superior de Música
                    Reina Sofía in Madrid. Dort wurde sie mit dem Sobresaliente-Preis
                    ausgezeichnet. 2017 zog sie nach Frankfurt am Main, um ihr Studium bei
                    Ingrid Zur an der HfMDK Frankfurt fortzusetzen; sie schloss 2020 ihr
                    Masterstudium ab.</p>
                  <p>Von 2019 bis 2021 spielte sie mit einem Zeitvertrag beim Frankfurter Opern-
                    und Museumsorchester. Sie ist als Gast-Solo-Bratscherin bei der
                    Mecklenburgischen Staatskapelle Schwerin eingeladen. Als Solistin und
                    Kammermusikerin widmet sie sich Kooperationsprojekten. Sie hat bisher mit
                    Chören wie dem Frankfurter Kammerchor und dem Kammerchor Rhein-Main
                    sowie mit den Tanzensembles wie dem Westdeutschen Tanztheater
                    Düsseldorf zusammengearbeitet. Seit 2019 spielt sie auf einer Bratsche von
                    Ritz Iwata aus Amsterdam.</p>
              </>
            ),
          },
          {
            image: teamImg4,
            title:(
              <>
                  <div className="info-text">
                      <h3>Weronika Dziadek</h3>
                      <p>Violina</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Weronika Dziadek, geboren 1995 in Katowice (Polen), studierte zunächst Violine bei Zakhar Bron an der Escuela Superior de Música Reina Sofía in Madrid, bevor sie zur Viola wechselte und ihr Masterstudium in der Bratschenklasse bei Nobuko Imai fortsetzte. </p>
                  <p className='middle-p'>Sie ist Preisträgerin zahlreicher Wettbewerbe, darunter der zweite Preis bei der Oskar Nedbal International Viola Competition (bei nicht vergebenem erstem Preis) und der erste Preis bei der Breslavia National Violin Competition. Während ihres Studiums an der Escuela Superior de Música Reina Sofía in Madrid war Weronika Dziadek Mitglied des Freixenet Symphony Orchestra, mit dem sie unter der Leitung von Zubin Mehta, Péter Eötvös, Pablo González, Andrés Orozco-Estrada, Pablo Heras-Casado und András Schiff europaweit auftrat. Als Solistin und Kammermusikerin arbeitete sie mit Künstlern wie Maxim Vengerov, Miriam Fried, Ning Feng, Mauricio Fuks und Natalia Prishepenko zusammen und trat in renommierten Konzertsälen auf, darunter die Nationalphilharmonie Warschau, die Baltische Philharmonie in Danzig, die Krakauer Philharmonie, das Auditorio Nacional de Música und das Teatro Real in Madrid. </p>
                  <p>2023-2024 studierte Weronika an der Kronberg Acadeny bei Nobuko Imai. Das Studium wurde ermöglicht durch das Dorothea Neuhaus-Patronat. </p>
              </>
            ),
          },
          {
            image: teamImg5,
            title:(
              <>
                  <div className="info-text">
                      <h3>Claire Wells</h3>
                      <p>Violine</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Claire Wells captivates audiences with a sound that speaks straight from her heart. Unique in
her sparkling imagination and original, honest interpretations, Wells brings a profound presence
to the stage with a transparency that touches her listeners.</p>
                    <p className='middle-p'>Solo concert engagements have brought her to halls like the Wigmore Hall, the Meyerson
Symphony Center, Bass Performance Hall, Teatro Degollado, and Konzerthaus Berlin, and the
American violinist has taken to the stage with major symphonies such as the Dallas Symphony
Orchestra, Konzerthausorchester Berlin, the Fort Worth Symphony Orchestra, the Belgrade
Philharmonic, the Jalisco Philharmonic, and the Nancy Lyric Symphony, among others. The
coming 2025-2026 season includes solo performances with the Stuttgarter Philharmoniker and
Czech Symphony Orchestra Prague.
                    </p>
                    <p className='middle-p'>Having taken major prizes from several international competitions such as the Indianapolis and
Mirecourt competitions, Claire Wells is the Mendelssohn-Prize First Prize and Commission Prize
winner at the Felix Mendelssohn Bartholdy Competition 2021 along with the 2nd Prize award
winner at the Michael Hill International Violin Competition 2023. Claire is the 2023 scholarship
winner of the Mozart Gesellschaft Dortmund.</p>
                    <p className='middle-p'>Having always held a special place in her heart for chamber music, Claire has been invited to
perform at several international festivals such as Chamber Music Northwest, Chamber Music
Connects the World, the Gstaad Festival, Krzyzowa Festival and the Verbier Festival. Claire has
the pleasure of frequently collaborating with some of the worlds’ top young musicians, as well as
sharing the stage with world renowned musicians such as Noah Bendix-Balgley, Christian
Tetzlaff, Lars Anders Tomter, Enrico Pace, Nobuko Imai, amongst others.</p>
                    <p>Born in 2001 to a family of musicians, Claire Wells began playing the violin and piano at the age
of 3. Claire studied at the Yehudi Menuhin School with teacher Lutsia Ibragimova, and the Köln
Hochschule für Musik und Tanz with Mihaela Martin. Claire has since 2022 continued her
studies with Mihaela Martin at the Kronberg Academy.</p>
<p>Claire Wells plays on a Nicolo Amati and a Grand Adam bow, on loan from a generous donor.</p>
              </>
            ),
          },
          {
            image: teamImg6,
            title:(
              <>
                  <div className="info-text">
                      <h3>Frederick Winterson</h3>
                      <p>Violoncello</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Frederick Winterson wurde in eine musikalische Familie englischer und
                    deutscher Eltern geboren. 2017 absolvierte er an der Hochschule für Musik
                    und darstellende Kunst Frankfurt am Main sein Bachelor-Studium mit
                    Auszeichnung, wo er bei Bonian Tian (Solo-Cellist des Gürzenich-Orchesters
                    Köln) studierte. 2019 folgte sein Master of Performance an der Guildhall
                    School of Music and Drama in London als Student von Louise Hopkins, und als
                    Stipendiat des „Child Memorial-Award“. Frederick wurde seitdem zum Junior
                    Fellow der Schule ernannt. Sein an der Guildhall School gegründetes
                    Klaviertrio ‘Ìgnis’ gewann 2018 sowohl den St. James Piano-Prize, als auch den
                    Ivan Sutton-Wettbewerb.</p>
                  <p className='middle-p'>In Deutschland erspielte Frederick sich 1. Preise bei Jugend musiziert und
                    dem Kammermusikwettbewerb Köln. 2016 erhielt er einen Sonderpreis an der
                    Sommerakademie Schloss Heiligenberg und ihm wurde anschließend von der
                    Kallioppe-Stiftung ein Cellobogen von Heinrich Knopf zur Verfügung gestellt.
                    Frederick hat an renommierten Konzertorten wie dem Barbican, der
                    Wigmore-Hall, der Kölner Philharmonie, der Alten Oper Frankfurt, dem
                    Gasteig München und der Tonhalle Düsseldorf gespielt.</p>
              </>
            ),
          },          {
            image: teamImg7,
            title:(
              <>
                  <div className="info-text">
                      <h3>Sophie Wenzel</h3>
                      <p>Mezzosopran</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Die Mezzosopranistin Sophie Wenzel entdeckte bereits früh ihre Liebe zur Musik und erhielt während ihrer 
                    Schulzeit zunächst Unterricht in Klavier und Violine. Auf ein Bachelorstudium in Gesang und Gesangspädagogik 
                    am Dr. Hoch’s Konservatorium folgte ein künstlerisches Masterstudium Gesang an der HfMDK bei Prof. 
                    Thomas Heyer in Frankfurt. Während dieser Zeit entdeckte die Sängerin - auch geprägt durch eine intensive 
                    Zusammenarbeit mit Helmut Deutsch - ihre große Liebe zum Liedrepertoire. 2016 war Sophie Wenzel 
                    Stipendiatin des Richard-Wagner-Verbands Frankfurt. Seit 2018 ist die gebürtige Frankfurterin als 
                    freischaffende Sängerin und Gesangspädagogin tätig.</p>
                  <p className='middle-p'>Auf der Opernbühne glänzt die lyrische Mezzosopranistin besonders in Rollen wie Hänsel oder Cherubino, in welchen sie ihr verschmitztes schauspielerisches Talent zum Ausdruck bringt. Die vielseitige Künstlerin tritt international als Konzert- und Liedsängerin auf und ist regelmäßig auch mit Genres wie Chanson, Musical und Pop zu hören. Sie war als Performerin bereits für verschiedene Stimm- und Tanzperformances in Frankfurt engagiert. Sophie Wenzel liebt es zu moderieren und begeisterte zuletzt als Sprecherin im Rahmen einer Kinder-Konzertlesung zur Komponistin Luise Greger.</p>
                    <p className='middle-p'>Zu ihren wichtigsten Lehrer:innen zählen Marina Unruh, Prof. Thomas Heyer, Hedayet Djeddikar, Noelle Turner und Helmut Deutsch.  </p>
                    <p className='middle-p'>Als ausgebildete Atem-, Sprech- und Stimmtherapeutin hat sie Erfahrung in der Behandlung aller logopädischer Störungsbilder und spezialisiert sich seit einigen Jahren auf die therapeutische Behandlung von Stimmen. Im Herbst 2021 gründete sie das „Studio für Stimme“ in Frankfurt. Dort begleitet sie Stimmprofis und Vielsprecher*innen mit einer Kombination aus Gesangsunterricht, Sprechtraining und Stimmtherapie. </p>
              </>
            ),
          },
          {
            image: teamImg8,
            title:(
              <>
                  <div className="info-text">
                      <h3>Anastasia Vorotnaya</h3>
                      <p>Klavier</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Anastasia Vorotnaya, hailed as “A Prodigy
of the 21st Century” by Musical Life
Magazine, is recognized internationally for
her captivating performances and
extraordinary musical depth. A notable
moment in her career came in 2017, when
she performed Beethoven’s Concerto No. 3
under the baton of Sir András Schiff,
accompanied by the Orquesta de Cámara
de la Escuela Superior de Música Reina
Sofía at the Auditorio Nacional in Madrid.
This landmark performance not only
showcased her technical brilliance but also
cemented her place as one of the leading
pianists of her generation.</p>
                  <p className='middle-p'>In the years since, Anastasia has continued
to achieve remarkable success, most
recently earning 2nd Prize at the 2024 Vigo
International Piano Competition in Spain
and being named a finalist and prize
winner at the 2023 Iturbi International
Piano Competition. These
accomplishments follow her 2021 triumph
at the Third Vienna Piano Competition,
where she took home the Gold Medal,
adding to her already impressive repertoire
of first-prize awards that date back to her
childhood.</p>
                    <p className='middle-p'>Anastasia’s rise to prominence began
early, marked by victories in major
competitions around the world. At just 15
years old, she won the prestigious
Concertino-Praga International Piano
Competition, and her resulting commercial
recording—featuring works by Chopin,
Liszt, Martinů, Ravel, and Scriabin—
remains in rotation on European radio
stations, including the BBC. This early
achievement not only established her as a
rising star but also demonstrated the depth
of her interpretative skills, setting the tone
for her future career.</p>
                    <p className='middle-p'>Beyond the accolades, it is her artistry that
captivates audiences. With performances
in over 20 countries across Europe, Asia,
and North America, Anastasia has graced
the stages of some of the world’s most
iconic concert halls, including Carnegie
Hall in New York, Musikverein in Vienna,
the Rudolfinum in Prague, and the Moscow
State Conservatory. Her international
presence has been complemented by a
deep connection to her native Russia,
where she has performed extensively in
cities from Ekaterinburg to Volgograd, and
appeared at historic cultural institutions like
the Armoury Museum of the Kremlin and
Tchaikovsky Hall in Moscow.</p>
                    <p>What sets Anastasia apart is not just her
technical precision, but her profound
emotional expression and ability to connect
with audiences. She has been a featured
artist at prominent music festivals such as
the Rheingau Music Festival in Germany,
Klosters Music Festival in Switzerland, the
Menuhin Music Festival in Switzerland, and
the Vozvrashenie Music Festival in Russia,
consistently earning praise for the
sensitivity and depth she brings to every
performance.
Her journey as an artist has been shaped
by world-class training. Beginning her
formal education at the Togliatti Academy,
she went on to study at the Central Music
School of the Moscow State Conservatory
under Vasily Ermakov. She then went to
Madrid, where she trained under the
legendary Dmitri Bashkirov at the Escuela
Superior de Música Reina Sofía, earning
both her Bachelor and Master of Music
degrees. Her studies culminated in the
United States, where she pursued an Artist
Diploma at Park University under Stanislav
Ioudenitch, further honing her craft.
Currently, Anastasia continues her artistic
development at the prestigious Lake Como
International Piano Academy, where she
has been a student since 2016. Her
dedication to refining her artistry, combined
with her natural talent, makes her a
compelling and sought-after artist in the
classical music world.</p>
              </>
            ),
          },
          {
            image: teamImg9,
            title:(
              <>
                  <div className="info-text">
                      <h3>Dana Barak</h3>
                      <p>Klarinette</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Die künstlerischen Aktivitäten der vielseitigen Musikerin Dana Barak reichen von Solo-
Auftritten bis zu Gastspielen bei Orchestern, von Aufführungen auf historischen Instrumenten,

von einer großen Bandbreite von Kammermusik bis hin zu ihren eigenen Kompositionen und
für sie eigens geschriebenen Werken. Sie ist als Klarinettistin wie als Komponistin auf vielen
Radio-Sendern zu hören, als letztes beispielsweise im Hessischen Rundfunk mit ihrer
Komposition „Wa Jechi le Achi“ für Klarinette und Kammerorchester oder ihr Trio-Album
„Tränen der Musen“, welches u.a. ihr „Suita Zeira“ enthält.</p>
                  <p className='middle-p'>Im April 2024 wurde Ihre
Komposition „Trio Ha Reut“ für Klarinette, Violoncello und Klavier im Casals Forum
Kronberg uraufgeführt und im Mai 2024 hat das Ensemble Modern Frankfurt ihre Komposition
„Ad Mea we Essrim!“ für zwei Klarinetten -Prof. Jörg Widmann zum 50zigsten Geburtstag
gewidmet- uraufgeführt. Über das klassische Repertoire hinaus hat sie sich auf
Aufführungspraxis der Neuen Musik spezialisiert und spielt u.a. im Ensemble Modern in
Frankfurt und im Ensemble Phoenix in Basel. Neben ihren eigenen Kompositionen erarbeitet
Dana Barak Werke mit Komponisten wie George Benjamin, Pascal Dusapin, Helmut
Lachenmann oder Jörg Widmann. Dana Barak gewann verschiedene Preise und erhielt
Stipendien, wie etwa von der America-Israel Culture Foundation, dem DAAD-Stipendium oder
der Schweizerischen Eidgenossenschaft.</p>
                    <p className='middle-p'>Nach ihrem Studium bei dem zu früh verstorbenen Professor Wolfgang Meyer in Karlsruhe
sowie bei Professor Jörg Widmann in Freiburg hat Dana Barak einen weiteren Master in
zeitgenössischer Musikaufführung in Basel erhalten. Außerdem war Sie Mitglied der
„International Ensemble Modern Akademie“ in Frankfurt und spezialisierte sich anschließend
im Bereich Kammermusik bei Professor Angelika Merkle in Frankfurt.</p>
              </>
            ),
          },
          {
            image: teamImg10,
            title:(
              <>
                  <div className="info-text">
                      <h3>Unai Sánchez</h3>
                      <p>Violoncello</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Unai Sánchez (2001), studied between Bilbao, Madrid and San Sebastián with Prof. María
Casado, Prof. Asier Polo and Prof. Pavel Gomziakov. He is currently studying for a master&#39;s
degree at the HfMDK under the direction of Prof. Jan Ickert, while expanding his knowledge of
baroque music with Prof. Kristin von der Goltz and contemporary music with Prof. Lucas Fels.</p>
                  <p className='middle-p'>In 2023 he was selected as a scholarship holder by the Deutschlandstipendium (sponsored by
the ‘Oscar und Vera Ritter Stiftung’) and in 2024 by the ‘arteMusica - Stiftung’ to continue his
studies. In addition, the ‘Rotary Club Offenbach-Dreieich’ decided to sponsor. In 2024 he won
the scholarship of the ‘Diputación Foral de Bizkaia’ for the academic year 24-25.</p>
                    <p className='middle-p'>Throughout his musical career, Unai has participated in numerous masterclasses and his talent
has been recognised through various awards such as the Honourable Mention in the ‘Young
Musicians of the Basque Country Competition’, the Grand Prize-Exaequo in the ‘Festival4Arts
International String Competition’ or the 2nd Prize in the ‘International Mozart Competition
Vienna’. With the chamber music project ‘Dúo Arriaga’ he was finalist in the ‘II. Concurso
Internacional Ciutat de Manises’ and in the “Concurso Internacional Antón García Abril 2024”.</p>
                    <p className='middle-p'>He has also performed as a soloist with different orchestras and participated in different
festivals. In addition, he collaborates with organizations to bring music to marginalized
communities and with the association ‘ESAS’.</p>
                    <p>Unai has a strong teaching vocation and currently teaches at the Frankfurter Klavierschule and
                    to private students.</p>
              </>
            ),
          },
          {
            image: teamImg11,
            title:(
              <>
                  <div className="info-text">
                      <h3>Manon Parmentier</h3>
                      <p>Klavier</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Die französische Pianistin Manon Parmentier ist seit der Spielzeit 2023/24 Solokorrepetitorin am Theater und Orchester Heidelberg. 
                    Sie schloss einen Master in Musikwissenschaft in Wien und einen Master Korrepetition an der Musikhochschule in Stuttgart ab. Bereits 
                    während ihres Studiums nahm Manon Parmentier an Opernproduktionen der Staatsoper und des Wilhelma Theaters Stuttgart, sowie der Festspiele 
                    Göppingen teil.</p>
                  <p className='middle-p'>2016 wurde sie als Korrepetitorin an das Gärtnerplatztheater in München eingeladen und ein Jahr 
                    später ins Opernstudio in Straßburg.Von 2019 bis 2022 war sie als Korrepetitorin an der Opéra national du Rhin in Straßburg 
                    angestellt und spielte unter anderem den Klavierpart in »Anatevka«, »Le Journal d´un disparu«, »El amor brujo« und »West Side 
                    Story« (Regie: Barrie Kosky) zusammen mit dem Orchestre Symphonique Mulhouse. In der Spielzeit 2022/23 wurde sie von den Opernhäusern 
                    Heidelberg, Heilbronn, Dijon, Metz und Nancy als Korrepetitorin engagiert. Sie erweiterte ihr Repertoire mit Opern, die das erste Mal 
                    in Frankreich aufgeführt wurden: »Stiffelio« von Verdi mit Jonathan Tetelman in der Hauptrolle und »Manru« von Paderewski.Manon Parmentier 
                    begleitet regelmäßig Chöre wie die Gaechinger Cantorey, korrepetierte Schauspieler*nnen des Théâtre National in Straßburg, musizierte mit 
                    Künstler*nnen wie Mark Padmore, Stéphanie d’Oustrac und dem Cellisten Edgar Moreau und arbeitete mit renommierten Dirigent*nnen wie Ariane 
                    Matiakh, Marta Gardolińska, Andrea Sanguineti, David Reiland, Debora Waldman, Lukasz Borowicz, Christophe Rousset und David Charles Abell 
                    zusammen.Beim Goodmesh Concours 2021 gewann sie den zweiten Preis sowie den Preis für das beste interdisziplinäre Projekt.</p>
              </>
            ),
          },
          {
            image: teamImg12,
            title:(
              <>
                  <div className="info-text">
                      <h3>Johannes Fritsche</h3>
                      <p>Bariton</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Johannes Fritsche ist seit der Spielzeit 23/24 Ensemblemitglied am Pfalztheater Kaiserslautern. Er sang bereits an den Theatern Freiburg, 
                    Koblenz, Görlitz und Zwickau. Neben zahlreichen Uraufführungen, u.a. bei den Osterfestspielen Baden-Baden, zählen Rollen wie Guglielmo, 
                    Figaro, Herr Fluth, Zar Peter, Albert und Papageno zu seinen gesungenen Partien.</p>
                  <p className='middle-p'>Mit einem breitgefächerten Konzertrepertoire ist der Bariton international zu hören, 
                    zuletzt in der Philharmonie Köln, der Liederhalle Stuttgart und dem Teatro Olimpico Vicenza. Er trat mit dem 
                    Freiburger Barockorchester, der Württembergischen Philharmonie, dem Folkwang Kammerorchester, dem ensemble recherche, 
                    dem Stuttgarter Kammerorchester und der Philharmonie Baden-Baden auf.</p>
                    <p>Johannes Fritsche studierte an der Hochschule Stuttgart Gesang bei Prof. Ulrike Sonntag. Weitere Impulse erhielt er 
                        bei Meisterkursen mit Brigitte Fassbaender, Michael Volle, Bo Skovhus und Hartmut Höll. Er ist mehrfacher Preisträger 
                        internationaler Wettbewerbe (Goodmesh Concours, HIDALGO Liedwettbewerb) 
                        und produzierte den Lied-Podcast „Achtung! Komponistin“.</p>
              </>
            ),
          },
          {
            image: teamImg13,
            title:(
              <>
                  <div className="info-text">
                      <h3>Karolina Aavik</h3>
                      <p>Klavier</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Karolina Aavik ist eine gefeierte estnische Pianistin, die 2023 an der neuen STAGE+ Reihe
„Rising Stars“ der Deutschen Grammophon teilnimmt. Sie widmet sich hauptsächlich der
Kammermusik und konzertiert regelmäßig in ihrem Heimatland und in ganz Europa.</p>
                  <p className='middle-p'>Karolinas Konzertprogramme beinhalten regelmäßig estnische Musik, so spielte das
Aavik-Duo beim Usedomer Musikfestival in Deutschland ein Programm mit estnischen
Kompositionen. Karolina Aavik absolviert derzeit ihr Studium bei Prof. Angelika Merkle an
der Hochschule für Musik und Darstellende Kunst Frankfurt am Main. Weitere wertvolle
Unterstützung erhielt sie in Wien von Prof. Christoph Eggner und Prof. Jevgueni Sinaiski.
Wichtige musikalische Impulse erhielt sie von Künstlerpersönlichkeiten wie Kirill Gerstein,
Robert Levin, Steven Isserlis, Claudio Martínez Mehner, Sophia Rahman, Dénes Várjon,
Cristian Macelaru, Iván Fischer, Gary Hoffman und anderen.</p>
                    <p>Karolina hat am Pärnu Musikfestival, Usedomer Musikfestival, Festival „Klaver“, Tallinner
Kammermusikfestival, etc. teilgenommen. Sie hat an mehreren Projekten der Kronberg
Academy teilgenommen und mit Dmytro Udovychenko, Hana Chang, Ivan Skanavi und
Elias David Moncado und anderen gespielt.</p>
<p>Karolina ist Preisträgerin mehrerer Wettbewerbe sowohl als Solistin als auch als
Kammermusikerin, darunter „Polytechnische Gesellschaft Frankfurt“, „Ilmari Hannikainen
Piano Chamber Music Competition“, „VII International Stasis Vainiunas Competition“.</p>
<p>Seit 2019 wird Karolina Aavik von Yehudi Menuhin Live Music Now Frankfurt am Main
unterstützt.</p>
              </>
            ),
          }
        
      ];
    const openModal = (contentIndex) => {
        setSelectedContent(modalContent[contentIndex]);
        setShowModal(true);
      };
    
      const closeModal = () => {
        setShowModal(false);
        setSelectedContent(null);
      };

    const [activeTab, setActiveTab] = useState('monday'); 

    const handleTabClick = (tabId) => {
      setActiveTab(tabId);
    };

    
    return <>


        <section id="ÜberDasFestival" className="about-event section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Über das Festival</h1>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xs-12 wow fadeInRight d-flex justify-content-center align-items-center" data-wow-delay="0.3s">
                        <img className="img-fluid" src={aboutImg} alt=""/>
                </div>
                <div className="col-md-6 col-lg-6 col-xs-12 wow fadeInLeft text-justify" data-wow-delay="0.3s">
                        <p className="intro-desc wow fadeInUp" style={{ fontSize: '21px', fontWeight: 700,marginBottom: '10px',marginTop: '10px' }} data-wow-delay="0.3s">Grußwort</p>
                        <p className="intro-desc wow fadeInUp" data-wow-delay="0.3s">Als Gründerin und künstlerische Leiterin des Komponistinnen Musikfestivals in Frankfurt am Main heiße ich Sie herzlich auf unserer Website willkommen.
                        Das KomponistInnen Musikfestival wurde im Jahr 2022 ins Leben gerufen und hat sich seitdem zu einem jährlichen Höhepunkt musikalischer Exzellenz entwickelt. Es ist weit mehr als nur eine musikalische Veranstaltung; es repräsentiert ein kulturelles Erbe, das wir in unserer Stadt Frankfurt mit Stolz bewahren und teilen. Wir sind fest davon überzeugt, dass unser Festival nicht nur die künstlerische Vielfalt in Frankfurt auf neue Weise bereichert, sondern auch einen positiven Einfluss auf die gesamte Musikszene der Stadt ausübt.
                        Wir laden Sie herzlich dazu ein, Teil dieser musikalischen Reise zu werden und gemeinsam mit uns außergewöhnliche Talente und die Vielfalt der Komponistinnenmusik zu feiern.
                        Werfen Sie einen Blick auf unser Programm und sichern Sie sich Ihre Tickets für dieses unvergessliche Erlebnis.</p>                 
                        <p className="intro-desc wow fadeInUp" data-wow-delay="0.3s" style={{ marginTop: '10px' }}>Herzlichst</p>
                        <p className="intro-desc wow fadeInUp" data-wow-delay="0.3s">Diana Sahakyan, Konzert Pianistin</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="countdown-timer section-padding">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="heading-count">
                            <h2 className="wow fadeInDown" data-wow-delay="0.2s">DAS FESTIVAL BEGINNT IN</h2>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row time-countdown justify-content-center wow fadeInUp" data-wow-delay="0.2s">
                            <div id="clock" className="time-count"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="counter-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
                        <div className="counter wow fadeInRight" data-wow-delay="0.3s">
                            <div className="icon"><i className="lni-map"></i></div>
                            <p>Orangerie im Günthersburgpark</p>
                            <span>Frankfurt am Main</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
                        <div className="counter wow fadeInRight" data-wow-delay="0.6s">
                            <div className="icon"><i className="lni-timer"></i></div>
                            <p>23. - 25. November, 2024</p>
                            <span>19:00 PM – 22:00 PM</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
                        <div className="counter wow fadeInRight" data-wow-delay="0.9s">
                            <div className="icon"><i className="lni-users"></i></div>
                            <p>100 Verfügbare plätze</p>
                            <span>Reservieren sie ihr ticket unten!</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
                        <div className="counter wow fadeInRight" data-wow-delay="1.2s">
                            <div className="icon"><i className="lni-heart"></i></div>
                            <p>Getränke & snacks</p>
                            <span>Verpassen sie es nicht</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="Konzerte" className="schedule section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">KONZERTE</h1>
                        </div>
                    </div>
                </div>
                <div className="schedule-area row wow fadeInDown" data-wow-delay="0.3s">
                    <div className="schedule-tab-title col-md-3 col-lg-3 col-xs-12">
                        <div className="table-responsive" style={{ overflowX: 'unset' }}>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'monday' ? 'active' : ''}`}
                                    id="monday-tab" data-toggle="tab"  role="tab"
                                    aria-controls="monday" aria-selected={activeTab === 'monday'} onClick={() => handleTabClick('monday')}>
                                    <div className="item-text">
                                    <h4>Samstag</h4>
                                    <h5>23 November</h5>
                                    </div>
                                </a>
                                </li>
                                <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'tuesday' ? 'active' : ''}`}
                                    id="tuesday-tab" data-toggle="tab"  role="tab" aria-controls="tuesday"
                                    aria-selected={activeTab === 'tuesday'} onClick={() => handleTabClick('tuesday')}>
                                    <div className="item-text">
                                    <h4>Sonntag</h4>
                                    <h5>24 November</h5>
                                    </div>
                                </a>
                                </li>
                                <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'wednesday' ? 'active' : ''}`}
                                    id="wednesday-tab" data-toggle="tab"  role="tab" aria-controls="wednesday"
                                    aria-selected={activeTab === 'wednesday'} onClick={() => handleTabClick('wednesday')}>
                                    <div className="item-text">
                                    <h4>Montag</h4>
                                    <h5>25 November</h5>
                                    </div>
                                </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="schedule-tab-content col-md-9 col-lg-9 col-xs-12 clearfix">
                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${activeTab === 'monday' ? 'active show' : ''}`} id="monday" role="tabpanel" aria-labelledby="monday-tab">
                            <div id="accordion">
                                <div className="card">
                                    <div id="headingOne">
                                        <div className="collapsed card-header">
                                            <span className="time">Beginn: 20 Uhr, Einlass: 19:30 Uhr</span>
                                            <h4>Eröffnungskonzert - Frankreich und die Romantik</h4>
                                            <p>Cécile Chaminade – Ausgewählte Lieder im Fokus</p>
                                            <p>Mel Bonis - Klavierquartett B Dur op. 69</p>
                                            <p>Nadia Boulanger – 3 piéces pour violoncello et piano</p>
                                            <p>César Franck – Sonate für Klavier und Violine A - Dur</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'tuesday' ? 'active show' : ''}`} id="tuesday" role="tabpanel" aria-labelledby="tuesday-tab">
                            <div id="accordion">
                                <div className="card">
                                    <div id="headingOne">
                                        <div className="collapsed card-header">
                                            <span className="time">Beginn: 18:30 Uhr, Einlass: 18:00 Uhr</span>
                                            <h4>Deutschlands größte Meister und Meisterinnen</h4>
                                            <p>Fanny Hensel – „Das Jahr“ Klavier Solo</p>
                                            <p>Ludwig van Beethoven – Klaviertrio op.11 „Gassenhauer – Trio“</p>
                                            <p>Dana Barak (*1987) - Trio Ha Reut  für Klarinette, Violoncello und Klavier</p>
                                            <p>Fanny Hensel – Klaviertrio op.11 d - moll</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'wednesday' ? 'active show' : ''}`} id="wednesday" role="tabpanel" aria-labelledby="wednesday-tab">
                            <div id="accordion">
                                <div className="card">
                                    <div id="headingOne">
                                        <div className="collapsed card-header">
                                            <span className="time">Beginn: 20 Uhr, Einlass: 19:30 Uhr</span>
                                            <h4>Zwischen Tradition und Moderne: Brahms und Gubaidulina</h4>
                                            <p>Sofia Gubaidulina – Klavierquintett</p>
                                            <p>Johannes Brahms – Klavierquintett f – Moll, op. 34</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="Künstler*Innen" className="section-padding text-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Musiker*innen</h1>                        
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg1} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.dianasahakyan.de/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(0)}>Diana Sahakyan</a></h3>
                                <p>Klavier</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.4s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg2} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.instagram.com/maximilianhoermeyer/?hl=en" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(1)}>Maximilian Hörmeyer</a></h3>
                                <p>Violine</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="1s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg7} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.sophiewenzel.de/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(6)}>Sophie Wenzel</a></h3>
                                <p>Mezzosopran</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.6s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg3} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.mihokawaiviola.com/bio" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(2)}>Miho Kawai</a></h3>
                                <p>Viola</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="1s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg4} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://weronikajdziadek.com/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(3)}>Weronika Dziadek</a></h3>
                                <p>Viola</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="1s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg6} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.instagram.com/frederick.winterson_cellist/?hl=en" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(5)}>Frederick Winterson</a></h3>
                                <p>Violoncello</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.8s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg5} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.instagram.com/claire.wells/?hl=en" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(4)}>Claire Wells</a></h3>
                                <p>Violine</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg8} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.anastasia-vorotnaya.com/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(7)}>Anastasia Vorotnaya</a></h3>
                                <p>Klavier</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg9} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.danabarakmusic.com/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(8)}>Dana Barak</a></h3>
                                <p>Klarinette</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg10} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://unaisanchez.com/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(9)}>Unai Sánchez</a></h3>
                                <p>Violoncello</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg11} alt=""/>
                                <div className="team-overlay">
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(10)}>Manon Parmentier</a></h3>
                                <p>Klavier</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg12} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://johannesfritsche.de/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(11)}>Johannes Fritsche</a></h3>
                                <p>Bariton</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg13} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.karolina-aavik.com/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(12)}>Karolina Aavik</a></h3>
                                <p>Klavier</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent showModal={showModal} closeModal={closeModal} content={selectedContent} />
        </section>
        <section id="Galerie" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Festival-Galerie</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg1} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg2} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg3} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg4} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg5} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg6} />
                    </div>
                </div>
            </div>
        </section>
        <section id="Tickets" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Tickets</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6 col-xa-12 mb-3">
                        <div className="price-block-wrapper wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="icon">
                                <i className="lni-ticket"></i>
                            </div>
                            <div className="colmun-title">
                                <h5>Ticket Normalpreis</h5>
                            </div>
                            <div className="price">
                                <h2>€20</h2>
                            </div>
                            <a href="#Ticketreservierung" className="btn btn-common">Ticket Reservieren</a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xa-12 mb-3">
                        <div className="price-block-wrapper wow fadeInUp" data-wow-delay="0.3s">
                            <div className="icon">
                                <i className="lni-ticket"></i>
                            </div>
                            <div className="colmun-title">
                                <h5>Ticket Ermäßigt</h5>
                            </div>
                            <div className="price">
                                <h2>€15</h2>
                            </div>
                            <a href="#Ticketreservierung" className="btn btn-common">Ticket Reservieren</a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xa-12 mb-3">
                        <div className="price-block-wrapper wow fadeInRight" data-wow-delay="0.4s">
                            <div className="icon">
                                <i className="lni-ticket"></i>
                            </div>
                            <div className="colmun-title">
                                <h5>Kombiticket</h5>
                            </div>
                            <div className="price">
                                <h2>€50</h2>
                            </div>
                            <a href="#Ticketreservierung" className="btn btn-common">Ticket Reservieren</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="Ticketreservierung" className="section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Ticketreservierung</h1>
                        </div>
                    </div>
                        <TicketSubscription/>
                </div>
            </div>
        </section>
        <section id="Kontakt">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <meta name="robots" content="noindex, nofollow" />
                        <object style={{ border: '0', height: '450px', width: '100%' }} data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2557.668828225911!2d8.704975396511683!3d50.12991871213213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0fc43eaab613%3A0x21839f3d431ee7db!2sOrangerie!5e0!3m2!1sen!2sde!4v1692725495647!5m2!1sen!2sde"></object>
                    </div>
                </div>
            </div>
        </section>
    </>
};

export default BodySection;
